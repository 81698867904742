<template>
	<div class="box_list">
		<div v-for="(item, index) in tj_data" :key="index" class="linemsg">
			<div>{{ index + 1 }}、{{ item.name }}</div>
			<div v-if="item.type === 2||item.type === 3">
				<table>
					<thead>
						<tr>
							<th style="width: 60%;">选项</th>
							<th style="width: 10%;">小计</th>
							<th>比例</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(xxItem, xxIndex) in item.xx" :key="xxIndex">
							<td>{{ xxItem.value }}</td>
							<td>{{ xxItem.number }}</td>
							<td>
								<div class="jdt">
									<div class="bar">
										<div class="colorjd" :style="{ width: xxItem.bl * 100 + '%' }"></div>
									</div>{{ (xxItem.bl * 100).toFixed(2) }}%
								</div>
							</td>
						</tr>
						<tr class="heji">
							<td>本题有效填写人次</td>
							<!-- <td>{{ item.xx.reduce((sum, xxItem) => sum + xxItem.number, 0) }}</td> -->
							<td>{{ item.rci }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="item.type === 1||item.type === 4" class="chartsbox" :ref="'chart' + index"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import '@/utils/echarts-wordcloud.js';

	export default {
		props: {
			tj_data: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {

			};
		},
		watch: {
			tj_data: {
				handler(newVal) {
					this.$nextTick(()=>{
						this.initializeCharts(); // 在 tj_data 变化时重新初始化图表
					})					
				},
				deep: true // 监听数组内部变化
			}
		},
		mounted() {
			
		},
		methods: {
			initializeCharts() {
				this.tj_data.forEach((item, index) => {
					if (item.type === 1||item.type === 4) {
						this.initChart(item, index);
					}
				});
			},
			initChart(item, index) {
				const chartRef = this.$refs['chart' + index];
				if (chartRef && chartRef.length > 0) {
					const myChart = echarts.init(chartRef[0]);
					const list = item.xx.map(xxItem => ({
						value: xxItem.number,
						name: xxItem.da
					}));
					const option = {
						tooltip: {
							show: true,
							borderColor: '#fe9a8bb3',
							borderWidth: 1,
							padding: [10, 15, 10, 15],
							confine: true,
							backgroundColor: 'rgba(255, 255, 255, .9)',
							textStyle: {
								color: 'hotpink',
								lineHeight: 22
							},
							extraCssText: 'box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;'
						},
						series: [{
							type: 'wordCloud',
							shape: 'pentagon',
							left: 'center',
							top: 'center',
							width: '100%',
							height: '100%',
							right: null,
							bottom: null,
							sizeRange: [14, 50],
							rotationRange: [0, 0],
							rotationStep: 0,
							gridSize: 25,
							drawOutOfBound: false,
							layoutAnimation: true,
							textStyle: {
								fontFamily: 'PingFangSC-Semibold',
								fontWeight: 600,
								color: function(params) {
									let colors = ['#fe9a8bb3', '#fe9a8bb3', '#fe9a8b03', '#9E87FFb3',
										'#9E87FFb3', '#9E87FFb3', '#fe9a8bb3', '#fe9a8bb3',
										'#fe9a8bb3', '#73DDFF', '#58D5FF'
									]
									return colors[parseInt(Math.random() * 10)];
								},
							},
							emphasis: {
								focus: 'none',
							},
							data: list,
						}, ],
					};
					myChart.setOption(option);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.linemsg {
		margin-bottom: 25px;
	}

	.chartsbox {
		width: 100%;
		height: 150px;
		margin-top: 10px;
	}

	.box_list {
		width: 100%;
		height: 60vh;
		overflow-y: auto;
		padding: 20px;
		font-size: 16px;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		margin: 10px 0;
		font-size: 14px;
		text-align: left;
	}

	th,
	td {
		padding: 8px 12px;
		border: 1px solid #ddd;
		text-align: center;
	}

	.bar {
		display: inline-block;
		height: 13px;
		background-color: #eee;
		margin-right: 10px;
		width: 60%;
		position: relative;
	}

	.jdt {
		display: flex;
		align-items: center;
	}

	.colorjd {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		background-color: #409EFF;
	}

	.heji {
		font-weight: bold;
	}
</style>